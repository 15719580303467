import type { AppGlobal } from '@/lib/parsers/globals/globals';
import en from '@/messages/en.json';
import { useTranslations } from 'next-intl';
import Icon from '../Icon';
import Btn, { BtnProps } from '../ui/Btn';
import { TxtProps } from '../ui/Txt';

export type SocialLinks = AppGlobal<'social_GlobalSet'>['socialLinks'];

export type SocialProps = TxtProps<
  'span',
  {
    links?: SocialLinks;
    BtnProps?: BtnProps;
    includeName?: boolean;
  }
>;

type SocialKeys = keyof (typeof en)['social'];

const Socials = ({ links, includeName, BtnProps }: SocialProps) => {
  const t = useTranslations('social');
  const socialLinks = Object.entries(links ?? {});
  let target = '_blank';

  return (
    !!socialLinks.length &&
    socialLinks.map(([key, value]) => {
      if (!value) return null;
      let icon: JSX.Element | null = null;

      switch (key) {
        case 'linkedin':
          icon = <Icon name="linkedin" />;
          break;
        case 'facebook':
          icon = <Icon name="facebook" />;
          break;
        case 'instagram':
          icon = <Icon name="instagram" />;
          break;
        case 'twitter':
          icon = <Icon name="twitter" />;
          break;
        case 'youtube':
          icon = <Icon name="youtube" />;
          break;
        case 'email':
          icon = <Icon name="email" />;
          break;
        default:
          break;
      }

      if (!icon) return null;

      if (key === 'email') {
        value = `mailto:${value}`;
        target = '_self';
      }

      function isSocialKey(value: string): string | null {
        const socialKeys = Object.keys(en.social) as Array<SocialKeys>;
        return socialKeys.includes(value as SocialKeys) ? t(value as SocialKeys) : null;
      }

      return (
        <Btn
          key={key}
          variant="unstyled"
          size="large"
          as="a"
          href={value}
          target={target}
          {...BtnProps}>
          {icon} {includeName ? isSocialKey(key) : null}
        </Btn>
      );
    })
  );
};

export default Socials;
