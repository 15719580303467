'use client';

import dynamic from 'next/dynamic';

const PreviewToolbarClient = dynamic(() => import('./PreviewToolbarClient'));

type PreviewToolbarProps = {
  enabled?: boolean;
};

const PreviewToolbar = ({ enabled }: PreviewToolbarProps) => {
  if (!enabled) return null;

  return <PreviewToolbarClient />;
};

export default PreviewToolbar;
